import { useState } from 'react';

// import { TextHighlighter } from 'components/Events/TextHighlighter';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ReactComponent as ToneIcon } from 'assets/icons/wifi.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';

import { Badge } from 'components/Badge';
import { Event } from 'models/events';

import { getThreatDetailsByCode, getInferences, getInferenceDetailsByCode } from './data';

interface EmailAnalysisProps {
  event: Event;
}

export function EmailAnalysis({ event }: EmailAnalysisProps) {
  const [isStretched, setIsStretched] = useState(false);

  const handleStretch = () => {
    setIsStretched((prev) => !prev);
  };

  const getAllInferences = () => {
    let allInferences: string[] = [];
    ['sender', 'recipients', 'attachments', 'links'].forEach((key) => {
      const inferences = getInferences(key, event);
      allInferences = allInferences.concat(inferences);
    });
    return allInferences;
  };

  const MAX_DISPLAYED_BADGES = 3;

  const [expandedBadges, setExpandedBadges] = useState(false);

  const getAllBadges: () => React.ReactNode[] = () => {
    const badges: React.ReactNode[] = [];

    event.threats?.forEach((threat) => {
      const threatDetails = getThreatDetailsByCode(threat.code);
      badges.push(
        <Badge
          icon={<AlertIcon className="fill-critical-red w-3.5 h-3.5" />}
          text={threatDetails.text}
          bgColor={threatDetails.bgColor}
          borderColor={threatDetails.borderColor}
          textColor={threatDetails.textColor}
          borderRadius="rounded"
          customCssClass="mono"
        />
      );
    });

    getAllInferences().forEach((inference) => {
      const threatDetails = getInferenceDetailsByCode(inference);
      badges.push(
        <Badge
          icon={<AlertIcon className="fill-critical-red w-3.5 h-3.5" />}
          text={threatDetails.text}
          bgColor={threatDetails.bgColor}
          borderColor={threatDetails.borderColor}
          textColor={threatDetails.textColor}
          borderRadius="rounded"
          customCssClass="mono"
        />
      );
    });

    event.tone?.forEach((tone) => {
      badges.push(
        <Badge
          icon={<ToneIcon className="w-3.5 h-3.5" />}
          text={tone}
          bgColor="soft-purple"
          textColor="dark-pink"
          customCssClass="mono"
          borderColor="soft-purple"
          borderRadius="rounded"
        />
      );
    });

    event.intent?.forEach((intent) => {
      badges.push(
        <Badge
          icon={<EyeIcon className="w-3.5 h-3.5" />}
          text={intent}
          bgColor="soft-sand"
          textColor="dark-yellow"
          customCssClass="mono"
          borderColor="soft-sand"
          borderRadius="rounded"
        />
      );
    });

    return badges;
  };

  const badges = getAllBadges();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <span>Analysis</span>
        <button
          type="button"
          className="bg-border-primary text-light-grey text-2xs px-1.5 py-0.5 rounded"
        >
          BETA
        </button>
      </div>
      <div className="flex flex-wrap gap-2">
        {badges.slice(0, MAX_DISPLAYED_BADGES).map((badge) => badge)}
        {badges.length > MAX_DISPLAYED_BADGES && expandedBadges && (
          <>{badges.slice(MAX_DISPLAYED_BADGES).map((badge) => badge)}</>
        )}
        {expandedBadges ? (
          <Badge
            text="Hide"
            bgColor="light-grey"
            textColor="white"
            borderColor="light-grey"
            borderRadius="rounded"
            customCssClass="mono"
            onClick={() => setExpandedBadges(false)}
          />
        ) : (
          <Badge
            text={`+${badges.length - MAX_DISPLAYED_BADGES} MORE`}
            bgColor="light-grey"
            textColor="white"
            borderColor="light-grey"
            borderRadius="rounded"
            customCssClass="mono"
            onClick={() => setExpandedBadges(true)}
          />
        )}
      </div>
      <div className="relative">
        <div className={`text-xs ${isStretched ? 'h-auto' : 'h-8'} overflow-hidden font-light`}>
          {event.reason}
          {!isStretched && (
            <div className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-t from-white to-transparent" />
          )}
        </div>
      </div>
      <button
        type="button"
        className="rounded-full text-black px-3 py-1 border-light border-border-primary w-fit text-2xs"
        onClick={() => handleStretch()}
      >
        {isStretched ? 'Hide' : 'Show'}
      </button>
    </div>
  );
}
