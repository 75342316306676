import { Metric } from 'models/metric';

export interface ThreatInferencesFilter {
  from?: string;
  to?: string;
  limit?: number;
  sort?: string;
  order?: string;
  threat?: string;
}

export interface ThreatInferencesResponse {
  hits: Metric[];
  total: number;
}

export interface ThreatFrequencyFilters {
  orgId: string;
  from?: string;
  to?: string;
  frequency?: string;
  threat?: string;
}

export interface Frequency {
  date: string;
  count: number;
}

export interface FrequencyThreat {
  code: string;
  frequency: Frequency[];
}

export interface ThreatFrequencyResponse {
  threats: FrequencyThreat[];
}

export enum ThreatType {
  VIPImpersonation = 'VIP_IMPERSONATION',
  VENDORImpersonation = 'VENDOR_IMPERSONATION',
  REGULATORImpersonation = 'REGULATOR_IMPERSONATION',
  MALWARE = 'MALWARE',
  BEC = 'BEC',
  FINANCIAL_FRAUD = 'FINANCIAL_FRAUD',
  ATTORNEY_FRAUD = 'ATTORNEY_FRAUD',
  INVOICE_FRAUD = 'INVOICE_FRAUD',
}
