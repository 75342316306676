import { useCallback, useEffect } from 'react';
import Shield from 'assets/illustrations/shield.png';
import { AdminConsentResult, OnboardingChildProps } from './interface';

function MicrosoftOnboarding({
  onConsentComplete,
  onConsentError,
  onConsentCancelled,
  onboardingInfo,
  isLoading,
}: OnboardingChildProps): JSX.Element {
  let checkChild: string | number | NodeJS.Timeout | undefined;

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const adminConsentUrl = onboardingInfo?.adminConsentUrl;
      if (!adminConsentUrl) return;
      const redirectUri = new URL(adminConsentUrl).searchParams.get('redirect_uri') || '';
      if (event.origin !== new URL(redirectUri).origin) return;

      const data = event.data as AdminConsentResult;
      if (data.type === 'ADMIN_CONSENT_RESULT') {
        if (data.status === 'success') {
          clearInterval(checkChild);
          onConsentComplete();
        } else {
          onConsentError(data.details);
        }
      }
    },
    [checkChild, onboardingInfo?.adminConsentUrl, onConsentComplete, onConsentError]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  const initiateConsent = async () => {
    try {
      const childWindow = window.open(
        onboardingInfo?.adminConsentUrl,
        'Admin Consent',
        'width=600,height=600'
      );

      if (childWindow) {
        checkChild = setInterval(() => {
          if (childWindow.closed) {
            clearInterval(checkChild);
            onConsentCancelled();
          }
        }, 500);
      } else {
        onConsentError('Failed to initiate admin consent');
      }
    } catch (err) {
      onConsentError(`Failed to initiate admin consent: ${err}`);
    }
  };

  return (
    <>
      <img className="w-16 mb-6 inline-block" src={Shield} alt="Privacy" />
      <div className="text-md font-normal w-72 pb-8 text-center">
        We require consent on behalf of the organisation to perform actions. Please grant RavenMail
        consent to continue.
      </div>
      <button
        type="button"
        onClick={initiateConsent}
        disabled={isLoading}
        className="bg-slate-900 text-white text-lg font-normal rounded-md w-80 h-14 disabled:bg-slate-700"
      >
        {isLoading ? (
          <div className="flex space-x-2 justify-center items-center h-1">
            <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.3s]" />
            <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.15s]" />
            <div className="h-2 w-2 bg-white rounded-full animate-ping" />
          </div>
        ) : (
          'Grant consent to RavenMail'
        )}
      </button>
    </>
  );
}

export default MicrosoftOnboarding;
