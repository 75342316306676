import { useState } from 'react';

import * as Tabs from '@radix-ui/react-tabs';
import { Badge } from 'components/Badge';
import AllEvents from 'components/Events';

import { BEC, IMPERSONATION, MALWARE } from 'constants/tabName';

import { GetEventsQueryParams } from 'models/events';
import { useGetFlaggedEventsQuery } from 'service/eventsApi';
import { useGetThreatInferencesQuery } from 'service/threatInferences';

import { kFormatter } from 'utils/numberFormatter';
import { useOrgId } from 'hooks/useOrgId';
import { ThreatType } from 'models/threatInferences';
import { useGetMalwareDetectionsQuery } from 'service/malwareDetections';

import { ThreatInferences } from './ThreatInferences';
import { ThreatsFrequency } from './ThreatFrequency';

type ThreatsTabsItem = {
  id: string;
  name: string;
  threatName: string;
};

export default function Threats() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [OrgId] = useOrgId();

  const tabs: ThreatsTabsItem[] = [
    {
      id: IMPERSONATION,
      name: 'Impersonation',
      threatName: `${ThreatType.VIPImpersonation},${ThreatType.VENDORImpersonation},${ThreatType.REGULATORImpersonation}`,
    },
    {
      id: MALWARE,
      name: 'Malware',
      threatName: ThreatType.MALWARE,
    },
    {
      id: BEC,
      name: 'BEC',
      threatName: `${ThreatType.BEC},${ThreatType.FINANCIAL_FRAUD},${ThreatType.ATTORNEY_FRAUD},${ThreatType.INVOICE_FRAUD}`,
    },
  ];

  const [tableFilters, setTableFilters] = useState<GetEventsQueryParams>({
    limit: 10,
    offset: 0,
    from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
    to: new Date().toISOString(),
    sortBy: 'time',
    sortOrder: 'desc',
    vector: '',
    threat: `${ThreatType.VIPImpersonation},${ThreatType.VENDORImpersonation},${ThreatType.REGULATORImpersonation}`, // By Default get VIP_IMPERSONATION events
  });

  const { data: flaggedData, isLoading } = useGetFlaggedEventsQuery({
    orgId: OrgId,
    ...tableFilters,
  });

  const { data: malwareData, isLoading: malwareLoading } = useGetFlaggedEventsQuery(
    {
      orgId: OrgId,
      ...tableFilters,
      threat: ThreatType.MALWARE,
    },
    {
      skip: activeTabIndex !== 1,
    }
  );

  const { data: inferenceData } = useGetThreatInferencesQuery({
    orgId: OrgId,
    threat: `${ThreatType.VIPImpersonation},${ThreatType.VENDORImpersonation},${ThreatType.REGULATORImpersonation}`,
  });

  const { data: becInferences } = useGetThreatInferencesQuery({
    orgId: OrgId,
    threat: ThreatType.BEC,
  });

  const { data: malwareDetection } = useGetMalwareDetectionsQuery({
    orgId: OrgId,
  });

  function getCountInFormat(tabId: string) {
    switch (tabId) {
      case IMPERSONATION:
        return kFormatter(inferenceData?.total || 0);
      case MALWARE:
        return kFormatter(malwareDetection?.total || 0);
      case BEC:
        return kFormatter(becInferences?.total || 0);
      default:
        return '0';
    }
  }

  // Update the events query filter with corresponding threat type
  const handleSetTab = (index: number) => {
    setActiveTabIndex(index);

    setTableFilters({
      limit: 10,
      offset: 0,
      from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
      to: new Date().toISOString(),
      sortBy: 'time',
      sortOrder: null,
      threat: tabs[index].threatName,
    });
  };

  return (
    <div className="mt-3">
      <Tabs.Root className="flex flex-col w-full" defaultValue={IMPERSONATION}>
        <Tabs.List className="flex items-center gap-20 border-b border-border-primary">
          {tabs.map((tab, index) => (
            <Tabs.Trigger
              key={tab.id}
              value={tab.id}
              className="text-light-grey  data-[state=active]:text-black text-sm flex items-center justify-start"
              onClick={() => handleSetTab(index)}
            >
              <div
                className={`flex items-center gap-2 py-3 px-1 ${activeTabIndex === index ? 'border-black border-b-2' : ''}`}
              >
                <span>{tab.name}</span>
                <div className="flex items-center gap-2">
                  <Badge
                    text={getCountInFormat(tab.id)}
                    bgColor={activeTabIndex === index ? 'black' : 'primary-background'}
                    borderColor={activeTabIndex === index ? 'white' : 'light-grey'}
                    textColor={activeTabIndex === index ? 'white' : 'light-grey'}
                  />
                </div>
              </div>
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        <div className="mt-8">
          <Tabs.Content id={IMPERSONATION} value={IMPERSONATION}>
            <div className="flex items-stretch gap-4">
              <ThreatInferences
                className="w-1/4"
                threatTitle="Impersonation Detections"
                total={inferenceData?.total || 0}
                inferences={inferenceData?.hits || []}
                comingSoon={[]}
              />
              <ThreatsFrequency tab="Impersonation" />
            </div>
            <AllEvents
              title="Impersonation Events"
              isLoading={isLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={flaggedData}
            />
          </Tabs.Content>
          <Tabs.Content id={MALWARE} value={MALWARE}>
            <div className="flex items-stretch gap-4">
              <ThreatInferences
                className="w-1/4"
                threatTitle="Malware Detections"
                total={malwareDetection?.total || 0}
                inferences={malwareDetection?.detections || []}
                comingSoon={[]}
              />
              <ThreatsFrequency tab="Malware" />
            </div>
            <AllEvents
              title="All Malware Events"
              isLoading={malwareLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={malwareData}
            />
          </Tabs.Content>
          <Tabs.Content id={BEC} value={BEC}>
            <div className="flex items-stretch gap-4">
              <ThreatInferences
                className="w-1/4"
                threatTitle="BEC Detections"
                total={becInferences?.total || 0}
                inferences={becInferences?.hits || []}
                comingSoon={[]}
              />
              <ThreatsFrequency tab="BEC" />
            </div>
            <AllEvents
              title="BEC Events"
              isLoading={isLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={flaggedData}
            />
          </Tabs.Content>
        </div>
      </Tabs.Root>
    </div>
  );
}
