import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRealm } from 'hooks/useRealm';
import { useOrgId } from 'hooks/useOrgId';
import KeycloakService from 'service/keycloak';
import { OnboardingStatusResponse, useLazyOnboardingStatusQuery } from 'service/onboardingApi';
import { OnboardingStatusTypes } from 'enums/onboardingStatusTypes';
import { IDP } from 'enums/idp';

type AuthContextType = {
  apiToken: () => string;
  authenticated: boolean;
  login: (realm: string, idpHint: IDP) => void;
  logout: () => void;
  initializing: boolean;
  name: string;
  email: string;
  mailadmin: boolean;
  superadmin: boolean;
  onboardingStatus: OnboardingStatusTypes;
  onboardingInfo: OnboardingStatusResponse | null;
  setOnboardingStatus: (status: OnboardingStatusTypes) => void;
  orgId: string;
};

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [realm] = useRealm();
  const [orgId, SetOrgId] = useOrgId();
  const isFirstTime = useRef(true);

  const [initializing, SetInitializing] = useState(true);
  const [onboardingStatus, setOnboardingStatus] =
    useState<OnboardingStatusTypes>('PENDING_ADMIN_CONSENT');
  const [onboardingInfo, setOnboardingInfo] = useState<OnboardingStatusResponse | null>(null);

  const [triggerOnboardingStatus, { data }] = useLazyOnboardingStatusQuery();

  useEffect(() => {
    if (!isFirstTime.current) {
      return;
    }
    SetInitializing(true);
    isFirstTime.current = false;
    KeycloakService.init(realm)
      .then((res) => {
        if (res && KeycloakService.isAuthenticated()) {
          setIsAuthenticated(true);
          SetOrgId(KeycloakService.getOrgId());
        } else {
          SetOrgId('');
          SetInitializing(false);
        }
      })
      .catch(() => {
        SetOrgId('');
        SetInitializing(false);
        setIsAuthenticated(false);
      });
  }, [SetOrgId, realm]);

  useEffect(() => {
    if (isAuthenticated && orgId) {
      triggerOnboardingStatus({ orgId });
    }
  }, [isAuthenticated, orgId, triggerOnboardingStatus]);

  useEffect(() => {
    if (data) {
      setOnboardingStatus(data.onboardingStatus);
      setOnboardingInfo(data);
      SetInitializing(false);
    }
  }, [data]);

  const value = useMemo(
    () => ({
      apiToken: () => KeycloakService.getToken() || '',
      authenticated: isAuthenticated,
      login: (r: string, idpHint: IDP) => {
        KeycloakService.login(r, idpHint);
      },
      logout: () => {
        KeycloakService.logout();
      },
      initializing: initializing,
      name: KeycloakService.getName(),
      email: KeycloakService.getEmail(),
      mailadmin: KeycloakService.isMailAdmin(),
      superadmin: KeycloakService.isSuperAdmin(),
      onboardingStatus: onboardingStatus || '',
      onboardingInfo: onboardingInfo || null,
      setOnboardingStatus,
      orgId,
    }),
    [isAuthenticated, initializing, onboardingStatus, onboardingInfo, orgId]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
