// GoogleOnboarding.tsx
import { ReactNode, useState } from 'react';
import Shield from 'assets/illustrations/shield.png';
import { ReactComponent as ClipboardIcon } from 'assets/icons/icon-clipboard.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/icon-link.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/icon-chevron-right.svg';
import { useVerifyDelegatedPermissionsMutation } from 'service/onboardingApi';
import { useAuth } from 'contexts/AuthContext';
import { getErrorMessage } from 'utils/errors';
import { OnboardingChildProps } from './interface';

interface GoogleCredentials {
  clientId: string;
  scopes: string[];
}

function GoogleOnboarding({
  onConsentComplete,
  onConsentError,
  onboardingInfo,
  isLoading,
}: OnboardingChildProps): JSX.Element {
  const [copiedField, setCopiedField] = useState<string>('');
  const { orgId } = useAuth();
  const [verifyDelegatedPermissions, { isLoading: isVerifying }] =
    useVerifyDelegatedPermissionsMutation();

  // Example credentials - replace with actual values from your context/props
  const credentials: GoogleCredentials = {
    clientId: onboardingInfo.serviceAccountId,
    scopes: onboardingInfo.scopes,
  };

  const handleCopy = async (text: string, field: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedField(field);
      setTimeout(() => setCopiedField(''), 2000);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy:', err);
    }
  };

  const verifyScopes = async () => {
    try {
      const response = await verifyDelegatedPermissions({ orgId }).unwrap();
      if (response.status === 'failed') {
        onConsentError(response.reason);
      } else {
        onConsentComplete();
      }
    } catch (err) {
      onConsentError(getErrorMessage(err));
    }
  };

  const renderField = (label: string, displayContent: ReactNode, copyValue: string) => (
    <div className="w-full mb-4">
      <div className="text-sm font-medium text-gray-700 mb-2">{label}</div>
      <div className="flex items-start gap-2">
        <div className="flex-grow p-3 bg-gray-50 rounded-lg text-sm font-mono break-all">
          {displayContent}
        </div>
        <div className="w-10">
          <button
            type="button"
            onClick={() => handleCopy(copyValue, label)}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            title="Copy to clipboard"
          >
            {copiedField === label ? (
              <div className="text-xs text-green-600 font-medium">Copied!</div>
            ) : (
              <ClipboardIcon className="w-5 h-5" />
            )}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-2xl p-6 pb-0">
      <div className="flex flex-row items-center justify-center space-x-2 mb-4">
        <img className="w-16" src={Shield} alt="Privacy" />
        <h1 className="text-center text-2xl font-medium">Set up Domain-wide Delegation</h1>
      </div>
      {renderField('Client ID', credentials.clientId, credentials.clientId)}
      {renderField(
        'OAuth Scopes',
        <div className="space-y-1 max-h-32 overflow-auto">
          {credentials.scopes.map((scope) => (
            <div key={scope}>{scope}</div>
          ))}
        </div>,
        credentials.scopes.join(',')
      )}

      <div className="mt-6 p-4 bg-blue-50 rounded-lg text-sm text-blue-700">
        <p className="mb-2">Please follow these steps:</p>
        <ol className="list-decimal ml-4 space-y-2">
          <li>Copy the Client ID and Scopes above</li>
          <li>Go to Google Workspace Admin Console</li>
          <li>Navigate to Security → API Controls → Domain-wide Delegation</li>
          <li>Click &quote;Add new&quote; and paste the copied values</li>
        </ol>
        <a
          href="https://admin.google.com/ac/owl/domainwidedelegation"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-800"
        >
          Open Admin Console
          <LinkIcon className="w-4 h-4 ml-1" />
        </a>
      </div>

      <button
        type="button"
        onClick={verifyScopes}
        disabled={isLoading}
        className="mt-6 w-full bg-slate-900 text-white py-3 px-4 rounded-lg flex items-center justify-center gap-2 hover:bg-slate-800 disabled:bg-slate-300 transition-colors"
      >
        {isLoading || isVerifying ? (
          <div className="flex space-x-2 items-center h-6">
            <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.3s]" />
            <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.15s]" />
            <div className="h-2 w-2 bg-white rounded-full animate-ping" />
          </div>
        ) : (
          <>
            Verify Permissions
            <ChevronRightIcon className="w-5 h-5" />
          </>
        )}
      </button>
    </div>
  );
}

export default GoogleOnboarding;
