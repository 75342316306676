interface BadgeProps {
  icon?: React.ReactNode;
  text: string;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  borderRadius?: string;
  customCssClass?: string;
  onClick?: () => void;
}

export function Badge({
  text,
  bgColor,
  borderColor,
  textColor,
  borderRadius = 'rounded-full',
  customCssClass = '',
  icon,
  onClick,
}: BadgeProps) {
  return (
    <button
      type="button"
      className={`flex items-center justify-center px-2 py-0.5 gap-2 ${borderRadius} border border-${borderColor} bg-${bgColor} ${customCssClass}`}
      onClick={onClick}
    >
      {icon}
      <span className={`text-center uppercase text-${textColor} text-xs`}>{text}</span>
    </button>
  );
}
