import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ONBOARD_ORGANIZATION,
  ONBOARDING_STATUS,
  VALIDATE_ORGANIZATION_CODE,
  VERIFY_GOOGLE_DELEGATED_PERMISSIONS,
} from 'constants/apiUrls';
import { IDP } from 'enums/idp';
import { OnboardingStatusTypes } from 'enums/onboardingStatusTypes';
import { baseQuery } from './baseQuery';

// Define the response type for the token exchange
export interface OnboardingStatusResponse {
  onboardingStatus: OnboardingStatusTypes;
  name: string;
  remoteId: string;
  adminConsentUrl: string;
  vendor: 'microsoft' | 'gmail';
  serviceAccountId: string;
  scopes: string[];
}

export interface OrgCodeValidationResponse {
  idpHint?: IDP;
}

export interface GooglePermissionCheckResponse {
  status: 'success' | 'failed';
  reason: string;
}

// Create the API slice
export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    onboardingStatus: builder.query<OnboardingStatusResponse, { orgId: string }>({
      query: ({ orgId }) => {
        return {
          url: `${ONBOARDING_STATUS(orgId)}`,
          method: 'GET',
        };
      },
    }),
    onboardOrg: builder.query<undefined, { orgId: string }>({
      query: ({ orgId }) => {
        return {
          url: `${ONBOARD_ORGANIZATION(orgId)}`,
          method: 'POST',
          body: {},
        };
      },
    }),
    validateOrgCode: builder.query<OrgCodeValidationResponse, { code: string }>({
      query: ({ code }) => {
        return {
          url: `${VALIDATE_ORGANIZATION_CODE(code)}`,
          method: 'GET',
        };
      },
    }),
    verifyDelegatedPermissions: builder.mutation<GooglePermissionCheckResponse, { orgId: string }>({
      query: ({ orgId }) => {
        return {
          url: `${VERIFY_GOOGLE_DELEGATED_PERMISSIONS(orgId)}`,
          method: 'GET',
        };
      },
    }),
  }),
});

// Export the generated hook
export const {
  useLazyOnboardingStatusQuery,
  useLazyOnboardOrgQuery,
  useLazyValidateOrgCodeQuery,
  useVerifyDelegatedPermissionsMutation,
} = onboardingApi;
